import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import dateFormat from 'dateformat';
import { Form, Row } from "react-bootstrap"

import InputField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/input'
import SelectField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/select'
import TextAreaField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/textarea'
import CheckboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/checkbox'
import RadioboxField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/radio'
import ButtonField from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/button'
import HtmlBox from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/html'
import ReCaptchaBox from 'gatsby-theme-starberry-lomondgroup/src/components/forms/elements/recaptcha'
import 'gatsby-theme-starberry-lomondgroup/src/components/forms/assets/styles/_index.scss'
import { postFormData, api_form_error } from "gatsby-theme-starberry-lomondgroup/src/components/forms/api/Api"
import { FormsSubject } from "gatsby-theme-starberry-lomondgroup/src/common/form-mail-subjects"
import { PageLinks } from "gatsby-theme-starberry-lomondgroup/src/common/page-links";
import { phoneNumberValidation, Mail_Function } from "gatsby-theme-starberry-lomondgroup/src/common/utils/site_utlis"
import { getUser } from "@myaccount/website-service"


function Book_A_Valuation(props) {
  const location = useLocation();
  const userData = getUser();

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showerrormsg, setShowerrorMsg] = useState("");
  const [showthankyou, setThankyou] = useState(false);
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectError, setSelectError] = useState({});

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  var fields = ([
      {
        element: "config",
        formname: "Book a Valuation",
        form_type: "Book a Valuation",
        error_text: "Highlighted fields are required.",
        success_text: "Thank you, a member of our team will be in contact soon.",
        email_temp_user: "book_a_valuation_user",
        email_temp_admin: "book_a_valuation_admin",
        email_server_func: "book_a_valuation",
        event_tracking: "book_a_valuation",
        page_url: location.href,
        email_subject_user: FormsSubject.book_a_valuation_sell.user_subject,
        email_subject_admin: FormsSubject.book_a_valuation_sell.admin_subject
      },
    ])

    if(process.env.GATSBY_SITE_NAME !== "Hardisty and Co"){
      fields.push(
        {
          grpmd: "12",
          label: "",
          placeholder: "",
          name: "sale_let",
          element: "radio",
          required: true,
          selected:"Sales",
          values: ['Sales', 'Lettings','Both'],
          labelClass: "annual-txt",
          class: "",
          divclass: "form-wrap"
        }, 
      )
    }
 
    fields.push(
      {
        grpmd: "12",
        label: "First Name",
        placeholder: "",
        name: "first_name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed.",
        divclass: "form-wrap",
        defaultValue: userData?.name || ``
      }, 
      {
        grpmd: "12",
        label: "Last Name",
        placeholder: "",
        name: "last_name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9._-]+(\\s+[a-zA-Z0-9._-]+)*$",
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed.",
        divclass: "form-wrap",
        defaultValue: userData?.surname || ``
      },      
      {
        grpmd: "12",
        label: "Email Address",
        placeholder: "",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid email id, Ex: james123@gmail.com, JAMES123@GMAIL.COM.",
        divclass: "form-wrap",
        defaultValue: userData?.email || ``
      },
      {
        grpmd: "12",
        label: "Phone Number",
        placeholder: "",
        name: "telephone",
        type: "text",
        element: "input",
        required: true,
        patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt",
        errorMsg: "Enter a valid phone number, Ex: 01234 567 890.",
        divclass: "form-wrap",
        defaultValue: userData?.mobile || ``
      },

      {
        grpmd: "12",
        label: "Property Address",
        placeholder: "",
        name: "property_address",
        type: "text",
        element: "input",
        required: true,
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed.",
        divclass: "form-wrap"
      },
      {
        grpmd: "12",
        label: "Postcode",
        placeholder: "",
        name: "postcode",
        type: "text",
        element: "input",
        required: true,
        labelClass: "annual-txt",
        errorMsg: "Alphanumeric and special characters (-_.) are only allowed.",
        divclass: "form-wrap"
      },
      {
        grpmd: "12",
        label: "I agree to receive newsletters and company updates as per the Privacy Policy.",
        placeholder: "I agree to receive newsletters and company updates as per the Privacy Policy.",
        name: "signup_newsletter",
        type: "checkbox",
        element: "checkbox",
        required: false,
        value:"yes",
        labelClass: "",
        class: "checkbox_cnt"
      },
      {
        grpmd: "12",
        name: "SUBMIT",
        type:"submit",
        element: "button",
        value: "Submit",
        class: "btn btn-green",
        labelClass: "",
        customClass:"custom-button-wrapper",
        divclass: "form-wrap"
      },
      {
        text: 'By proceeding, you agree to the terms set out in our <a href="/'+PageLinks.privacy_policy+'/">Privacy Policy.</a>',
        element: "html",
        class: "mt-3 text-center content_r-m"
      },
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
    );

  const handlechange = event => {
    // remove initial empty spaces
    //console.log("check_event",event.target.checked);
    if(event.type === "select"){
      //event.value = event.value.trimStart()
      setSelectedOptions(selectedOptions => ({...selectedOptions, event}));
    } else{
      if(event.target.name === "telephone"){
        var valid_phone = phoneNumberValidation(event.target.value);
        if(valid_phone){
          event.target.value = valid_phone;
        } else{
          event.target.value = '';
        }
      } else{
        event.target.value = event.target.value.trimStart()
      }
    }

  }

  useEffect(() => {

    //console.log("token", token);
    
    if (token !== '') {

    const processFromData = async () => {

      let formData = new FormData();

      formData.append('data', JSON.stringify(formvalues));
      setShowerror(false);
      postFormData(formData).then(async apiRes => {

          //console.log("apiRes", apiRes);

          window.grecaptcha.reset();

          if(apiRes?.success === true){
            // tracking event
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'formSubmit',
              'formType': 'form-'+fields[0].event_tracking,
              'formId': 'form-'+fields[0].event_tracking,
              'formName': fields[0].formname,
              'formLabel': fields[0].formname +" - "+formvalues.sale_let
            });

           
            setThankyou(true);
            setTimeout(() => {
              setThankyou(false)
            }, 6000);

            await Mail_Function(formvalues);
          } else{
            setShowerror(true);
            setThankyou(false);
            setShowerrorMsg(api_form_error)
          }
          
      });

      formvalues['g-recaptcha-response'] = token;

      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }

      
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    //console.log("event =>", form);

    var form_obj_arr = Object.entries(form);

    //console.log("form_obj_arr =>", form_obj_arr.filter(item => item[1].required === true));

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      setShowerrorMsg(fields[0].error_text)

      // if(form.sale_let.name === "sale_let"){
      //   setSelectError(selectError => ({...selectError, "sale_let":form.sale_let.value}));
      // }
      
      // if(selectedOptions?.value){
      //   setSelectError(false);
      // } else{
      //   setSelectError(true);
      // }
      

      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });      
    }
    else {

      event.preventDefault();
      const formsdata = (event.target);

      const json = {}
      Object.keys(formsdata).map(key => {

        if(formsdata[key].type === "checkbox"){
          if(formsdata[key].checked === true){
            json[formsdata[key].name] = 'yes'
          }
        } else if(formsdata[key].type === "radio"){
          if(formsdata[key].checked === true){
            json[formsdata[key].name] = formsdata[key].value
          }
        } else{
          json[formsdata[key].name] = formsdata[key].value
        }

      })

      //var date_time = dateFormat(startDate, "mmm dd, yyyy")+" "+dateFormat(startTime, "hh:MM TT");
      json['name'] = json.first_name+' '+json.last_name;
      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['email_subject_user'] = json.sale_let === "Lettings" ? FormsSubject.book_a_valuation_let.user_subject : fields[0].email_subject_user;
      json['email_subject_admin'] = json.sale_let === "Lettings" ? FormsSubject.book_a_valuation_let.admin_subject : fields[0].email_subject_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;
      json['address'] = json.property_address;
      json['postcode'] = json.postcode;
      json['search_type'] = json.sale_let === "Lettings" ? 'landlord' : "vendor";
      json['type'] = json.sale_let === "Lettings" ? 'landlord' : "vendor";
      json['meta'] = {};
      // json['date_time'] = date_time;
      // json['appointment_availability'] = {
      //   "hours": [dateFormat(date_time, "isoUtcDateTime")]
      // };

      if(json.sale_let === "Sales"){
        json['to_email_id'] = process.env.GATSBY_BOOK_VALUATION_MAIL_TO_SALES;
      } else if(json.sale_let === "Lettings"){
        json['to_email_id'] = process.env.GATSBY_BOOK_VALUATION_MAIL_TO;
      } else{
        json['to_email_id'] = process.env.GATSBY_BOOK_VALUATION_MAIL_TO+", "+process.env.GATSBY_BOOK_VALUATION_MAIL_TO_SALES;
      }
      

      // json['extra'] = {"property_address": json.property_address, "postcode": json.postcode, "i_had_like_to": json.sale_let, "preferred_date":dateFormat(startDate, "yyyy-dd-mm"), "preferred_time":dateFormat(startTime, "HH:MM"), "signup_newsletter": json.signup_newsletter, "to_email_id": json.to_email_id};
      json['extra'] = {"property_address": json.property_address, "postcode": json.postcode, "i_had_like_to": json.sale_let, "signup_newsletter": json.signup_newsletter, "to_email_id": json.to_email_id};


      //console.log("formsdata_json", json);

      setFormvalues(json);

      if(process.env.GATSBY_CAPTCHA_MODE === "test"){
        setToken("test");
      } else{
        recaptchaRef.current.execute();
      }

      setValidated(false);
      setSelectError(false);
      setSelectedOptions({});
      
      // reset form
      const form = event.target
      form.reset();
      setStartDate();
      setStartTime();
      
    }
  };

  //console.log("date_time", dateFormat("Oct 06, 2022 01:30 PM", "isoUtcDateTime"));

  return (
    <div className="form">
    
    {showerror && <div className="alert alert-danger">
      {showerrormsg}
    </div>}
    <div ref={myRef}></div>
    
    {showthankyou && <div className="alert alert-success">
      {fields[0].success_text}
    </div>}

    
      <Form name={fields[0].formname} className={props.classNames} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">
        
        <input type="hidden" name="form_name" value={"leadpro-valuation"} />
        <input type="hidden" name="ma_formname" value={"website-valuation"} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="form_label" value={props.formLabel} />
        {/* <input type="hidden" name="search_type" value={"vendor"} /> */}
        <input type="hidden" name="lead_type" value={"leads"} />
        {/* <input type="hidden" name="to_email_id" value={process.env.GATSBY_BOOK_VALUATION_MAIL_TO} /> */}
        <input type="hidden" name="admin_email" value={"No"} />
        
        <input type="hidden" name="bot-field" />

        {process.env.GATSBY_SITE_NAME === "Hardisty and Co" && <input type="hidden" name="sale_let" value={"Sales"} />}

        <Row className="custom-text-field-wrapper no_direction">
        {fields.map((field, index) => {
            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                  startDate={field.type === 'valuation_date' ? startDate : ''}
                  setStartDate={field.type === 'valuation_date' ? setStartDate : ''}
                  startTime={field.type === 'valuation_time' ? startTime : ''}
                  setStartTime={field.type === 'valuation_time' ? setStartTime : ''}
                  defaultValue={field?.defaultValue}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}                  
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                  //selectedValue={selectedOptions}
                  errorMsg={field.errorMsg}
                  //isError={selectError ? true : '' }
                  isError= {Object.keys(selectError).length > 0 ? (selectError[field.name] ? '' : true) : ''}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <>
                
                <TextAreaField
                  name={field.name}
                  divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                />
                </>
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("radio" === field.element) {
              return (
                <RadioboxField
                  name={field.name}
                  divclass={field.divclass}
                  ref={field.ref}
                  values={field.values}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  selected={field.selected}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <>
                
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                  grpmd={field.grpmd}
                  customClass={field.customClass}
                />
                
                </>
              );
            }
          })
        }
        </Row>
      </Form>

      
    </div>
  );
}


const ContactFormPage = (props) => (
  <Book_A_Valuation {...props} />
  
)

export default ContactFormPage